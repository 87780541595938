<template>
    <h2 class="title available" @click="$emit('switch-components', 'Projects')">
        Wanna go back to my projects?
    </h2>

    <ul
        ref="draggableContainer"
        @mousedown="dragMouse"
        class="gallery container--groups"
        v-show="!imageOpened"
    >
        <li :key="img" v-for="img in data" @click="openImage(img)">
            <img :src="img" alt="" />
        </li>
    </ul>
    <ActiveImage
        v-if="imageOpened"
        :activateImage="activateImage"
        @close-image="closeImage"
    />
</template>

<script>
import ActiveImage from './ActiveImage';
export default {
    name: 'Gallery',
    components: {
        ActiveImage,
    },
    data() {
        return {
            data: [],
            images: {
                inn: {
                    cover: '',
                    all: [],
                },
                ship: {
                    cover: '',
                    all: [],
                },
            },
            dragPosition: {
                scrollTop: 0,
                clientY: 0,
            },
            imageOpened: false,
            activateImage: '',
            dragCurrentPath: '',
            isDrag: false,
        };
    },
    mounted() {
        const inn_imgs = require.context('@/assets/inn/', true, /^.*\.jpg$/);
        this.importImages('inn', inn_imgs);

        const ship_imgs = require.context('@/assets/ship/', true, /^.*\.jpg$/);
        this.importImages('ship', ship_imgs);
    },
    methods: {
        importImages(set, imgsData) {
            imgsData.keys().forEach((img) => {
                img.includes('Cover')
                    ? (this.images[set].cover = imgsData(img))
                    : this.images[set].all.push(imgsData(img));

                // TODO: Remove later. This is only until I design a better use for the Cover images.
                this.data.push(imgsData(img));
            });
        },
        dragMouse: function(e) {
            e.preventDefault();
            this.dragPosition.clientY = e.clientY;
            this.dragCurrentPath = e.path[0].currentSrc;
            document.onmousemove = this.onDrag;
            document.onmouseup = this.endDrag;
        },
        onDrag: function(e) {
            e.preventDefault();
            this.isDrag = true;
            let drag = this.$refs.draggableContainer;

            drag.style.cursor = 'grabbing';
            drag.style.userSelect = 'none';
            drag.scrollBehavior = 'smooth';

            this.dragPosition.scrollTop = drag.scrollTop;
            let dragBy = e.clientY - this.dragPosition.clientY;

            drag.scrollTop =
                this.dragPosition.scrollTop -
                ((dragBy / Math.sqrt(Math.abs(dragBy))) * 2 || 0);
        },
        endDrag(e) {
            this.$refs.draggableContainer.style.cursor = 'grab';

            document.onmouseup = null;
            document.onmousemove = null;

            if (this.dragCurrentPath == e.path[0].currentSrc && this.isDrag) {
                e.stopPropagation();
                this.closeImage();
            }
            this.isDrag = false;
        },
        closeImage() {
            this.imageOpened = !this.imageOpened;
        },
        openImage(img) {
            this.closeImage();
            this.activateImage = img;
        },
        sendImage() {
            return this.activateImage;
        },
    },
    emits: ['switch-components'],
};
</script>

<style scoped>
.gallery {
    cursor: grab;
}
ul {
    position: relative;
    margin: 0;
    padding: 0;
    width: 100%;
    overflow: auto;

    display: flex;
    flex-wrap: wrap;
}
ul::after {
    content: '';
    display: block;
    flex-grow: 9;
}

li {
    height: clamp(10rem, 20vh, 30vh);
    list-style: none;
    box-sizing: border-box;
    padding: 0 0.25rem;
    margin: 0 0.5rem 0.5rem 0.5rem;
    flex-grow: 1;
}

img {
    max-height: 100%;
    min-width: 100%;
    object-fit: cover;
    vertical-align: bottom;
    border: 1px solid var(--clr-dark);
    border-radius: 0.6rem;
    box-shadow: var(--bs);
}
</style>
