<template>
    <h2 class="title">
        These are some of my projects:
    </h2>
    <div class="projects container--groups">
        <Project
            :title="title"
            :description="description"
            :key="title"
            v-for="[title, description] of Object.entries(projects)"
        />
    </div>
    <h2 class="title available" @click="$emit('switch-components', 'Gallery')">
        Wanna check out my Gallery?
    </h2>
</template>

<script>
import Project from './Project';
export default {
    name: 'Projects',
    components: {
        Project,
    },
    data() {
        return {
            projects: {
                'Depths of Hell':
                    '2D Bullet Hell Boss-Rush game in Unity. Created by a team of 13 working remotely from home. Designed Dynamic Difficulty Adjustment systems making every boss fight easier, or more difficult depending on how the player is performing. Implemented Sound Management and Visual Effects, along with structured scripting techniques that significantly improved the overall game performance. Developed testing versions for Windows, MacOS, and Linux devices to optimize performance, and ensure an enjoyable user experience.',
                'The Lost Cowboy':
                    'Roguelike 2D Game produced from a team of six. Procedural levels, Dungeon-crawl, Pixel-Based 8-bit style. The purpose of the game was to provide casual experience to players new to the genre. With high focus on C# coded controls, AI, Level Generation. Game uses procedurally generated world, filled with enemies using A* pathfinding algorithm. The player movement and camera behaviour are in axonometric 3/4 view.',
                'Resource Wall':
                    'Pinterest project in a group of three. Created SPA to save learning resources such as tutorials, blogs, videos, images. With user specific features and validations - Categorizing resources by topics with ability to comment / rate / like individual content. Implemented Thumbnail API for displaying screenshot of the website that is posted. If user is logged-in, a screenshot of the login page will be provided instead of revealing private information.',
            },
        };
    },
    methods: {},
    emits: ['switch-components'],
};
</script>
