<template>
    <Background />
    <div class="content--container">
        <Header />

        <Content />
        <Footer />
    </div>
</template>

<script>
import Background from './components/Background.vue';
import Header from './components/Header.vue';
import Content from './components/Content.vue';
import Footer from './components/Footer.vue';
export default {
    name: 'App',
    components: {
        Background,
        Header,
        Content,
        Footer,
    },
};
</script>

<style>
:root {
    --ff-important: Georgia, 'Times New Roman', Times, serif;
    --ff-primary: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
        Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    --ff-secondary: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS',
        sans-serif;
    --ff-body: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;

    --fw-300: 300;
    --fw-600: 600;
    --fw-900: 900;

    --clr-light: #fff;
    --clr-dark: #303030;
    --clr-accent: rgb(19, 182, 246);
    --clr-accent-light: rgb(82, 206, 255);
    --clr-accent-dark: rgb(0, 79, 110);

    --fs-h1: clamp(1.25rem, 2.5vw, 3.5rem);
    --fs-h2: clamp(1.25rem, 2.5vw, 2.25rem);
    --fs-h3: clamp(1rem, 2vw, 2rem);
    --fs-h4: clamp(0.8rem, 1.75vw, 1.5rem);
    --fs-body: clamp(0.8rem, 1.5vw, 1.25rem);

    --ls-important: 0.05em;
    --ls-body: 0.025rem;
    --ws-body: 0.025em;
    --lh: 1.3em;

    --sp: 1rem;
    --bs: 0.25em 0.25em 0.75em rgba(0, 0, 0, 0.25),
        0.125em 0.125em 0.25em rgba(0, 0, 0, 0.15);
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

html {
    font-size: 16px;
}

body,
h1,
h2,
h3,
h4,
p {
    margin: 0;
    color: var(--clr-light);
    letter-spacing: var(--ls-body);
    word-spacing: var(--ws-body);
}

body,
p {
    font-family: var(--ff-body);
    font-weight: var(--fw-300);
    font-size: var(--fs-body);
}

h1 {
    letter-spacing: var(--ls-important);
    font-family: var(--ff-important);
    font-weight: var(--fw-900);
    font-size: var(--fs-h1);
}

h2 {
    font-family: var(--ff-primary);
    font-weight: var(--fw-600);
    font-size: var(--fs-h2);
    line-height: 1;
}

h3 {
    font-family: var(--ff-secondary);
    font-weight: var(--fw-600);
    font-size: var(--fs-h3);
}

h4 {
    font-family: var(--ff-secondary);
    font-weight: var(--fw-600);
    font-size: var(--fs-h4);
}

img {
    display: block;
    max-width: 100%;
}

.content--container {
    position: absolute;
    height: 100%;
    width: 100%;
    padding: 0.75rem;
    display: flex;
    flex-direction: column;
    text-align: center;
}

header,
footer {
    flex-basis: 5%;
}

.empty {
    flex: 0 0 10%;
}
.email {
    flex: 1 1 80%;
}
.email p {
    width: unset;
}
.icons {
    flex: 0 0 10%;
}

.content {
    margin-block: 1rem;
    flex: 1 1 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.words--line {
    position: fixed;
    left: 0;
    width: 100%;
    border: 1px solid rgba(255, 255, 255);
}

.words,
.numbers {
    display: flex;
    justify-content: space-around;
}

.words {
    padding-top: 0.1em;
}

.numbers h4,
.words h4 {
    cursor: pointer;
}

.content--right,
section {
    height: 100%;
}

.content--right {
    overflow: auto;
}

.container {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-top: var(--sp);
}

.title {
    flex: 0 0 5%;
    margin-block: 0.75rem;
}

.subtitle {
    margin: auto;
    width: fit-content;
}

.subtitle::after {
    content: '';
    display: block;
    width: auto;
    height: 1px;
    background: white;
    margin: 0.025em auto 0.0515em;
    opacity: 0.175;
}

.container--groups {
    position: relative;
    height: 100%;
    overflow: auto;

    flex: 1 1 80%;
    align-self: center;
}

.group--items {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.group {
    margin-bottom: 1rem;
}

.item {
    display: flex;
    flex-direction: row;
    align-items: baseline;
}

.character {
    padding-top: 1rem;
}

.character--image img {
    width: clamp(15em, 20vw, 35em);
    margin-inline: auto;
    margin-bottom: 1em;
}
.character .group {
    margin-block: 1em;
}

.readiness .item + .item {
    padding-left: 0.5em;
}

.discipline .group--items {
    flex-direction: column;
    align-items: center;
}
.discipline .item {
    gap: 0.325em;
}

.text-width {
    width: clamp(20em, 80vw, 35em);
    margin-inline: auto;
}

.content--left {
    flex-basis: 2em;
}
.content--right {
    flex-basis: 100%;
}
/*
            * Scrollbar
*/
::-webkit-scrollbar {
    width: 0.5em;
}

::-webkit-scrollbar-thumb {
    background-image: radial-gradient(
        circle,
        var(--clr-accent) 0%,
        var(--clr-accent-dark) 30%,
        transparent 100%
    );

    border-radius: 0.5em;
}

::-webkit-scrollbar-thumb:hover {
    background-image: radial-gradient(
        circle,
        var(--clr-accent-light) 10%,
        var(--clr-accent-dark) 50%,
        transparent 100%
    );
}

.available {
    cursor: pointer;
    animation: flow 10s ease-in-out infinite;
    background: linear-gradient(-60deg, var(--clr-light), var(--clr-accent));
    background-size: 300%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

@-webkit-keyframes flow {
    0% {
        background-position: 0 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0 50%;
    }
}
</style>
