<template>
    <section class="discipline">
        <div class="container">
            <h2 class="title">
                Where I learned all this stuff ?
            </h2>
            <div class="container--groups">
                <div
                    class="group text-width"
                    :key="institute"
                    v-for="[institute, instituteData] of Object.entries(
                        institutes
                    )"
                >
                    <h3 class="subtitle">
                        {{ institute }}
                    </h3>
                    <div class="group--items">
                        <span class="item">
                            <h4>{{ instituteData.award }}</h4>
                        </span>
                        <span class="item">
                            <h4>Location:</h4>
                            <p>{{ instituteData.location }}</p>
                        </span>
                        <span class="item">
                            <h4>Date:</h4>
                            <p>{{ instituteData.endDate }}</p>
                        </span>
                        <span class="item" v-if="instituteData.info">
                            <p>{{ instituteData.info }}</p>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'Discipline',
    data() {
        return {
            institutes: {
                'University of Saskatchewan': {
                    location: 'Saskatchewan, SK',
                    info: '',
                    award: 'B.Sc. Computer Science',
                    date: 'September 2017 to April 2021',
                    startDate: 'September 2017',
                    endDate: 'April 2021',
                },
                'LightHouse Labs': {
                    location: 'Vancouver, BC',
                    info: '',
                    award: 'Diploma in Web Development',
                    date: 'March 2017 to May 2017',
                    startDate: 'March 2017',
                    endDate: 'May 2017',
                },
                'Humber College': {
                    location: 'Toronto, ON',
                    info: '',
                    award: 'OCAD in 3D Animation, Art & Design',
                    date: 'September 2011 to April 2014',
                    startDate: 'September 2011',
                    endDate: 'April 2014',
                },
                'Simon Fraser University': {
                    location: 'Vancouver, BC',
                    info: 'Some courses taken: Java, inDesign, Processing',
                    award: 'Interactive Arts & Technologies',
                    date: 'May 2015 to April 2016',
                    startDate: 'May 2015',
                    endDate: 'April 2016',
                },
            },
        };
    },
};
</script>
