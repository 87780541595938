<template>
    <div class="project group text-width">
        <h3 class="subtitle">
            {{ title }}
        </h3>
        <p>{{ description }}</p>
    </div>
</template>

<script>
export default {
    name: 'Project',
    props: ['title', 'description'],
};
</script>
