<template>
    <header>
        <div class="logo">
            <h1 data-text="GN">
                GN
            </h1>
        </div>
        <div class="name">
            <h1>
                Georgi Nikolov
            </h1>
        </div>
    </header>
</template>

<script>
export default {
    name: 'Header',
    data() {
        return {
            'data-text': 'Georgi Nikolov',
        };
    },
};
</script>

<style scoped>
header {
    display: flex;
    align-items: center;
}

.logo {
    width: 0;
}
.name {
    position: relative;
    width: 100%;
    margin: auto;
    text-align: center;
}

.logo h1 {
    line-height: 1em !important;
    position: relative;
    color: rgba(255, 255, 255, 0);
    text-transform: uppercase;
}
.logo h1::before {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    color: white;
    -webkit-text-stroke: 0vw black;
    border-right: 2px solid rgba(255, 255, 255, 0);
    overflow: hidden;
    animation: animate 6s cubic-bezier(0.37, -0.35, 0.32, 0.82) infinite;
}
@keyframes animate {
    0%,
    10%,
    100% {
        width: 0;
    }
    70%,
    90% {
        width: 2em;
    }
}
</style>
