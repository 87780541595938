<template>
    <section class="character">
        <div class="container">
            <div class="container--groups">
                <div class="character--image">
                    <img src="../assets/Me.png" alt="" />
                </div>
                <div class="character--about">
                    <h2 class="title">
                        What kind of professional am I ?
                    </h2>
                    <div class="group text-width">
                        <h3 class="subtitle">
                            Designer & Developer from Saskatoon
                        </h3>
                        <p class="text">
                            As a junior developer with degrees in Computer
                            Science, 3D Animation, and Art & Design, I am eager
                            to further explore creativity in software
                            development.
                        </p>
                    </div>
                    <div class="group text-width">
                        <h3 class="subtitle">
                            My Motivation
                        </h3>
                        <p class="text">
                            Meeting new people and learning from their stories
                            inspires me to be more passionate about my work. It
                            also gives me the ability to create an environment
                            that users fully enjoy interacting with.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'Character',
};
</script>
