<template>
    <section class="readiness">
        <div class="container">
            <h2 class="title">
                These are the skills that I have:
            </h2>
            <div class="container--groups">
                <div
                    class="group text-width"
                    :key="skill"
                    v-for="[skill, types] of Object.entries(skills)"
                >
                    <h3 class="subtitle">{{ skill }}:</h3>
                    <div class="group--items">
                        <div class="item" :key="type" v-for="type in types">
                            <p v-if="type !== types[types.length - 1]">
                                {{ type }},
                            </p>
                            <p v-else>
                                {{ type }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'Readiness',
    data() {
        return {
            skills: {
                Languages: [
                    'C#',
                    'HTML/CSS',
                    'Java',
                    'JavaScript',
                    'jQuery',
                    'PHP',
                    'Python',
                    'Ruby',
                    'SQL',
                ],
                Frameworks: [
                    'Laravel',
                    'Node.js',
                    'Rails',
                    'React.js',
                    'VueJs',
                ],
                Databases: ['MonogoDB', 'MySQL', 'Postgres'],
                OS: ['Linux', 'MacOS/Unix', 'Windows'],
                IDEs: ['Atom', 'Eclipse', 'PhpStorm', 'Visual Studio Code'],
                '3D & 2D': [
                    '3ds Max',
                    'After Effects',
                    'DDO',
                    'Illustrator',
                    'InDesign',
                    'MarmorSet Toolbag',
                    'Maya',
                    'Mudbox',
                    'NDO',
                    'Photoshop',
                    'Substance Designer',
                    'Substance Painter',
                    'UVLayout',
                    'xNormal',
                    'Zbrush',
                ],
                Engines: ['Unity', 'CryEngine', 'UnrealEngine4'],
                'Vision Control': ['Filezila', 'Git'],
            },
        };
    },
};
</script>
