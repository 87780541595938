<template>
    <span class="background__elements"></span>
</template>

<script>
export default {
    name: 'Background',
};
</script>

<style scoped>
.background__elements {
    position: fixed;
    width: 100%;
    height: 100%;
    background-image: url(../assets/sunset.jpg);
    background-size: cover;
    background-blend-mode: multiply;
    background-color: #303030;
    z-index: -100;
}
</style>
