<template>
    <div class="content">
        <ContentLeft @word-switch="wordSwitch" />
        <ContentRight :currentWord="currentWord" />
    </div>
</template>

<script>
import ContentLeft from './ContentLeft';
import ContentRight from './ContentRight';

export default {
    name: 'Content',
    components: {
        ContentLeft,
        ContentRight,
    },
    data() {
        return {
            currentWord: 'Character',
        };
    },
    methods: {
        wordSwitch(word) {
            this.currentWord = word;
        },
    },
};
</script>
