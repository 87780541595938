<template>
    <h4 :class="{ active: word.active }">{{ word.text }}</h4>
</template>

<script>
export default {
    name: 'Word',
    props: {
        word: Object,
    },
};
</script>

<style scoped>
h4:active {
    color: var(--clr-accent-light);
}

.active {
    color: var(--clr-accent);
}
</style>
