<template>
    <div class="content--right">
        <!-- <div class="line-through-center"></div> -->
        <component :is="currentWord" />
    </div>
</template>

<script>
import Character from './Character';
import Readiness from './Readiness';
import Undertaking from './Undertaking';
import Discipline from './Discipline';

export default {
    name: 'ContentRight',
    props: {
        currentWord: String,
    },
    components: {
        Character,
        Readiness,
        Undertaking,
        Discipline,
    },
};
</script>

<style scoped>
.line-through-center {
    z-index: 9999999999999999;
    position: absolute;
    display: inline-block;
    height: 100vh;
    top: 0;
    border-left: 1px solid red;
}
</style>
