<template>
    <h4 :class="{ active: number.active }">
        {{ '0' + number.id }}
    </h4>
</template>

<script>
export default {
    name: 'Numbers',
    props: {
        number: Object,
    },
};
</script>

<style scoped>
h4:active {
    color: var(--clr-accent-light);
}

.active {
    color: var(--clr-accent);
}
</style>
