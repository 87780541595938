<template>
    <section class="undertaking">
        <div class="container">
            <component
                :is="currentComponent"
                @switch-components="swapComponent"
            />
        </div>
    </section>
</template>

<script>
import Projects from './Projects';
import Gallery from './Gallery';
export default {
    name: 'Undertaking',
    components: {
        Projects,
        Gallery,
    },
    data() {
        return {
            currentComponent: 'Projects',
        };
    },
    methods: {
        swapComponent(component) {
            this.currentComponent = component;
        },
    },
};
</script>
