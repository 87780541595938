<template>
    <div
        class="content--left"
        @mousedown="mouseDown"
        @mouseup="mouseUp"
        @mousemove="mouseMove"
    >
        <div class="numbers">
            <Numbers
                v-for="word in words"
                :key="word.id"
                :number="{ id: word.id, active: word.active }"
            />
        </div>
        <Line />

        <div class="words">
            <Word
                v-for="word in words"
                :key="word.id"
                :word="word"
                @click="wordSwitch(word)"
            />
        </div>
    </div>
</template>

<script>
import Word from './Word';
import Line from './Line.vue';
import Numbers from './Numbers';
export default {
    name: 'ContentLeft',
    props: {
        number: String,
        text: String,
    },
    components: {
        Word,
        Numbers,
        Line,
    },
    data() {
        return {
            words: [
                {
                    id: 1,
                    text: 'Character',
                    active: true,
                },
                {
                    id: 2,
                    text: 'Readiness',
                    active: false,
                },

                {
                    id: 3,
                    text: 'Undertaking',
                    active: false,
                },

                {
                    id: 4,
                    text: 'Discipline',
                    active: false,
                },
            ],
            curWord: {},
            numbers: [],
            curYPos: 0,
            curDown: false,
        };
    },
    methods: {
        wordSwitch(word) {
            this.words.forEach((item) => {
                item.text === word.text
                    ? (item.active = true)
                    : (item.active = false);
                this.curWord = word;
            });

            this.$emit('word-switch', word.text);
        },
        mouseDown(e) {
            e.preventDefault();
            this.curYPos = e.y;
            this.curDown = true;
        },
        mouseUp(e) {
            e.preventDefault();
            this.curDown = false;
        },
        mouseMove(e) {
            e.preventDefault();
            if (this.curDown) {
                // let;
            }
        },
        allNumbers() {
            this.words.forEach((item) => {
                this.numbers.push('0' + item.id);
            });
            return this.numbers;
        },
    },
    emits: ['word-switch'],
    beforeMount() {
        this.curWord = this.words[0];
        this.numbers = this.allNumbers();
    },
};
</script>
