<template>
    <div class="activatedImage-wrapper" @click="$emit('close-image')">
        <img class="activatedImage" :src="activateImage" alt="" />
    </div>
</template>

<script>
export default {
    name: 'ActiveImage',
    props: { activateImage: String },
    emits: ['close-image'],
};
</script>

<style scoped>
.activatedImage-wrapper {
    position: fixed;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    cursor: pointer;
    user-select: none;
    background-color: rgba(0, 0, 0, 0.9);
}

.activatedImage {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 80%;
    min-width: 80%;
    border: 0.5px solid var(--clr-accent);
    border-radius: 0.6rem;
    z-index: 100;
    animation: animate 6s cubic-bezier(0.4, 0, 1, 0.46) infinite;
}

@keyframes animate {
    0%,
    100% {
        box-shadow: rgb(24 76 127) 0px 0px 0px 2px,
            rgb(0 137 255 / 47%) 0px -1px 6px 3px,
            rgb(0 138 255 / 6%) 2px -8px 36px 30px,
            rgb(0 138 255 / 25%) -5px -8px 22px 10px,
            rgb(0 63 86 / 51%) 0px 1px 0px inset;
    }
    50% {
        box-shadow: rgb(24 76 127) 0px 0px 0px 2px,
            rgb(0 137 255 / 47%) 0px 0 6px 2px, rgb(0 138 255 / 6%) 0 0 36px 1px,
            rgb(0 138 255 / 25%) 0 0 22px 2px,
            rgb(0 63 86 / 51%) 0px 1px 0px 2px inset;
    }
}
</style>
