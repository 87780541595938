<template>
    <footer>
        <div class="empty"></div>
        <div class="email"><p>georgi@gnikolov.ca</p></div>
        <div class="icons">
            <a class="icon" href="https://github.com/nikolovGeorgi/">
                <i class="fab fa-github"></i>
            </a>
            <a
                class="icon"
                href="https://www.linkedin.com/in/georgi-nikolov-63b60980/"
            >
                <i class="fab fa-linkedin-in"></i>
            </a>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'Footer',
};
</script>

<style scoped>
footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.fab {
    color: white;
}

.icons {
    display: flex;
}

.icon + .icon {
    padding-left: 1em;
}
</style>
